<template>
  <div>
    <div class="header">
      <div class="header-wrap">
        <div class="search-nav">
          <h1 class="logo-box" @click="$router.push('/')">
            <div class="img-box">
              <img
                style="transform: rotate(45deg)"
                src="../../assets/images/logo/logo-left.gif"
                alt=""
              />
              <img src="../../assets/images/logo/logo-right.jpg" alt="" />
            </div>
          </h1>
          <div class="search-wrap">
            <div class="input-wrapper">
              <el-input
                placeholder="请输入关键字"
                v-model.trim="keyword"
                @focus="handleFocus"
                @blur="handleBlur"
                @input="handleFocus"
                class="input-with-select"
              >
                <el-button slot="append" icon="el-icon-search" @click="search(keyword)"
                  >搜索</el-button
                >
              </el-input>
              <transition name="fade">
                <div
                  class="data-dialog"
                  v-if="historyList.length  && dialogActive"
                >
                  <div class="history">
                    <div class="item-record" v-for="item in historyList" :key="item" @mousedown.prevent @click="search(item)">{{item}}</div>
                  </div>
                  <div class="clear-data" @mousedown.prevent @click="clearHistoryData">
                    清空历史
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="car" @click="$router.push('/shoppingcart')">
            <img src="~@/assets/images/home/car.png" alt="" />
            <div>购物车</div>
            <!-- <RouterJump
              tag="div"
              active-class="active"
              to="/shoppingcart"
              text="购物车"
            ></RouterJump> -->
          </div>
          <el-popover placement="bottom" width="110" trigger="click">
            <img
              class="qrcode-img"
              src="~@/assets/images/home/qrcode.png"
              alt=""
            />
            <div class="qrcode" slot="reference">
              <img src="~@/assets/images/home/link.png" alt="" />
              <div>小程序</div>
            </div>
          </el-popover>
        </div>
        <div class="nav">
          <!-- <div :class="{ active: $route.path == '/' }" @click="jump('Home')">
            首页
          </div> -->
          <RouterJump
            tag="div"
            active-class="active"
            exact
            to="/"
            text="首页"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/enterprise/home"
            text="易缆原料"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/cable-mall"
            exact
            text="易缆商城"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/cable-finance"
            text="易缆数科"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/cable-logistics"
            text="易缆物流"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/articleList"
            text="易缆资讯"
          ></RouterJump>
          <RouterJump
            tag="div"
            active-class="active"
            to="/companyList"
            text="易缆企业"
          ></RouterJump>
        </div>
      </div>
      <div class="header-foot" v-if="isShow"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import RouterJump from "@/components/RouterJumpVue.vue";
export default {
  components: { RouterJump },
  data() {
    return {
      keyword: "",
      dialogActive: false,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    };
  },
  computed: {
    shopId() {
      return this.$route.params.id;
    },
    userinfo() {
      return this.$store.getters["user/userInfo"];
    },
    isLogin() {
      return this.$store.getters["user/isLogin"];
    },
    historyList(){
      return this.$store.getters['history/getHistory']
    },
    isShow() {
      return (
        this.$route.path.includes("/goods/details") ||
        this.$route.path.includes("/send_cable_logistics")
      );
    },
  },
  methods: {
    /* eslint-disable */
    search(keyword) {
      let path = this.$route.path;
      if (keyword == "") {
        return this.$message.error("搜索不能为空！");
      }
      let category = 1;
      if (path == "/articleList") {
        category = 3;
      } else if (path == "/companyList") {
        category = 2;
      } else {
        category = 1;
      }
      this.$router.push({
        path: "/search-results",
        query: { keyword, category: category },
      });
    },
    handleFocus(){
      this.dialogActive = this.keyword == ''
    },  
    handleBlur() {
      setTimeout(()=>{
        this.dialogActive = false;
      },0)
    },
    clearHistoryData() {
      this.$confirm("此操作将删除历史记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch('history/clearHistory')
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    jump(name) {
      if (this.isLogin && name == "login") {
        return false;
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    logout() {
      this.$store.dispatch("user/logout");
      // this.$router.push("/");
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-popover span {
  display: block;
  text-align: center;
  margin-bottom: 7px;
  cursor: pointer;
  &:hover {
    color: #e60012;
  }
  &:last-child {
    margin: 0;
  }
}
.header {
  box-sizing: border-box;
  padding: 12px 0;
  background: #ffffff;
  min-width: 1200px;
  position: relative;
  // overflow: hidden;
  .header-wrap {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    .search-nav {
      height: 60px;
      width: 1200px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      box-sizing: border-box;
      .logo-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 100px;
        cursor: pointer;
        .img-box {
          display: flex;
          align-items: center;
          img {
            &:nth-child(1) {
              width: 63px;
              height: 60px;
            }
            &:nth-child(2) {
              width: 95px;
              height: 47px;
            }
          }
        }
        p {
          padding: 0;
          margin: 0;
          margin-top: 6px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .search-wrap {
        margin: 7px 0;
        margin-right: 31px;
        .input-wrapper {
          position: relative;
          .data-dialog {
            position: absolute;
            left: 0;
            width: 600px;
            // min-height: 105px;
            background: #fff;
            box-shadow: 0px 4px 5px 0px rgba(33, 33, 33, 0.18);
            display: flex;
            padding: 20px;
            box-sizing: border-box;
            z-index:99;
            .clear-data {
              width: 60px;
              text-align: center;
              font-size: 14px;
              color: #007eff;
              line-height: 26px;
              height: 26px;
              padding-top: 6px;
              box-sizing: border-box;
              z-index: 99;
              cursor: pointer;
            }
            .history {
              width: 500px;
              display: flex;
              flex-wrap: wrap;
              .item-record {
                height: 26px;
                max-width: 110px;
                background: #ededed;
                border-radius: 4px;
                padding: 6px 10px;
                margin-right: 20px;
                margin-bottom: 10px;
                line-height: 26px;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                cursor: pointer;
              }
            }
          }
        }
        .el-input {
          height: 46px;
          width: 600px;
        }
        .el-input__inner {
          height: 46px;
          width: 470px;
        }
      }
      .car {
        width: 130px;
        height: 46px;
        font-size: 16px;
        color: #e60012;
        padding: 15px 26px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #e60012;
        border-radius: 4px;
        margin-right: 22px;
        cursor: pointer;
        img {
          height: 16px;
          width: 16px;
        }
      }
      .qrcode-img {
        height: 110px;
        width: 110px;
      }
      .qrcode {
        cursor: pointer;
        width: 130px;
        height: 46px;
        background: #ffefef;
        border-radius: 4px;
        font-size: 16px;
        color: #333333;
        padding: 15px 26px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          height: 16px;
          width: 16px;
        }
      }
      ::v-deep .el-select .el-input {
        width: 130px;
      }
      ::v-deep .input-with-select .el-input-group__prepend {
        background-color: #fff;
      }
      ::v-deep .el-input__inner {
        height: 46px;
        width: 470px;
        font-size: 18px;
        border: 2px solid #e60012;
      }
      ::v-deep .el-input-group__append {
        background: #e60012;
        color: #ffffff;
        width: 160px;
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        border: 1px solid #e60012;
      }
    }

    .nav {
      height: 45px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 0 40px;
      > div {
        width: 120px;
        position: relative;
        margin-right: 60px;
        font-size: 22px;
        color: #333333;
        text-align: center;
        cursor: pointer;
        &:hover {
          color: #e60012;
        }
        ::v-deep div {
          &.active {
            font-weight: bold;
            color: #e60012;
          }
          &.active::after {
            position: absolute;
            bottom: -18px;
            left: 0px;
            content: "";
            display: block;
            width: 100%;
            height: 4px;
            background: #e60012;
            border-radius: 2px;
          }
        }
      }
    }
  }
  .header-foot {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: #e60012;
  }
}
/* 定义进入和离开的过渡效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity:0;
}
</style>
